<template>
  <div>
    <div class="query">
      <el-select v-model="channelVal" placeholder="请选择渠道">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div style="display: inline-block;margin-left:20px;">
        <el-date-picker v-model="timevalue" :popper-append-to-body="false" :editable="false" popper-class="timet_datepicker" align="center" size="small" type="datetimerange" value-format="timestamp" :clearable="false" range-separator="至" start-placeholder="开始日期" :picker-options="pickerOptions" end-placeholder="结束日期" @change="changeTimec()" :default-time="defaultTime"></el-date-picker>
      </div>
      <div style="display: inline-block;margin-left:20px;">
        <el-button type="primary" @click="queryFun()">查询</el-button>
      </div>
    </div>
    <div class="pieChart">
      <pieChart :chart-data="perData" @perChartFun="perChartFun"></pieChart>
    </div>
    <div class="tableBox">
      <!-- <div class="export_btn">
        <el-button type="primary" icon="el-icon-document" @click="exportBtn()"> 导出 Excel </el-button>
      </div> -->
      <el-table :data="tableData" stripe style="width: 100%" row-class-name="rowClassName" :highlight-current-row="true">
        <el-table-column prop="ts" label="时间">
        </el-table-column>
        <el-table-column prop="orderId" label="订单id">
        </el-table-column>
        <el-table-column prop="deviceId" label="设备id"> </el-table-column>
        <el-table-column prop="channel" label="渠道"> </el-table-column>
        <el-table-column prop="orderStatus" label="状态"> </el-table-column>
        <el-table-column prop="frameCutNum" label="截帧数"> </el-table-column>
        <el-table-column prop="frameCutNum" label="截帧数"> </el-table-column>
        <el-table-column prop="successFrameCutNum" label="父订单成功截帧数"> </el-table-column>
        <el-table-column prop="failframeCutNum" label="父订单失败截帧数"> </el-table-column>
        <!-- <el-table-column prop="orderStatus" label="异常状态"> </el-table-column> -->
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="check(scope.row)">查看</el-button>
          </template>
          <!-- <el-button type="text">删除</el-button> -->
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="pageTotal" background layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
    <dialogTalbe ref="dialogTalbe" @sureEvent="sureEvent"></dialogTalbe>
  </div>
</template>

<script>
import pieChart from "./pieChart.vue";
import dialogTalbe from "./com/dialogTalbe.vue";
import dayjs from "dayjs";
export default {
  components: {
    pieChart,
    dialogTalbe,
  },
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      perData: {
        data: [],
      },
      tableData: [],

      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      options: [],
      channelVal: "tencent",
      defaultTime: ["00:00:00", "23:59:59"], //日期时间
      timevalue: [],
      pickerOptions: {},
      stime: "",
      etime: "",
      dateTime: "", //选择的时间范围
      perChartId:"",//饼图的id
    };
  },
  computed: {},
  methods: {
    //获取渠道列表数据
    getChannel() {
      let { baseUrl } = this;
      $.ajax({
        url: `${baseUrl}/frameStatic/channelParam`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {},
        success: (data) => {
          // console.log(data.data);
          if (data.code == "00000") {
            this.options = data.dataList;
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //获取当天时间的默认值
    getDate() {
      const start = new Date(new Date().toLocaleDateString());
      start.setTime(start.getTime());
      var end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1
      );
      this.windowetime = Math.round(end.getTime() / 1000);
      this.stime = start.getTime();
      this.etime = Math.round(end.getTime());
      var arr = [start.getTime(), Math.round(end.getTime())];
      this.timevalue = arr;
    },
    //选择时间
    changeTimec() {
      var startTime = "",
        endTime = "",
        newendTime = "";
      //   console.log(this.timevalue);
      if (this.timevalue == null) {
        this.stime = startTime;
        this.etime = newendTime;
      } else {
        startTime = this.timevalue[0] ;
        endTime = this.timevalue[1];
        newendTime = Math.round(endTime);
        this.stime = startTime;
        this.etime = newendTime;
      }
      // let dateTime = this.etime - this.stime; //时间范围
      // this.dateTime = dateTime * 1000;
      // let millisecondsOfDay = 24 * 60 * 60 * 1000;
      // if (this.dateTime > millisecondsOfDay) {
      //   this.$message.warning("超过24小时，重新选择");
      //   this.timevalue = [];
      // }
    },
   
    //翻页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(val,this.perChartId);
    },
    //获取饼图数据
    getPer() {
      let { baseUrl } = this;
      // console.log(id);
      let obj = {
        channel: this.channelVal,
        startTime: this.stime,
        endTime: this.etime,
      };
      $.ajax({
        url: `${baseUrl}/frameStatic/frameCutStateStatic`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: obj,
        success: (data) => {
          console.log(data.dataList);
          if (data.code == "00000") {
            //截帧数
            this.perData.data = data.dataList;
            // this.perData.xaxis = data.data.date;
          }
          // console.log(this.perData);
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //点击饼图
    perChartFun(val) {
      console.log(val);
      this.perChartId=val
      this.getData(1, val);
      this.currentPage = 1;
    },
    //查询
    queryFun() {
      this.getPer();
      //   this.getData();
    },
    //获取饼图数据
    getData(page, id) {
      let { baseUrl } = this;
      // console.log(id);
      let obj = {
        // channel: "tencent",
        // startTime: 1639681200000,
        // endTime: 1639695600000,
        channel: this.channelVal,
        startTime: this.stime,
        endTime: this.etime,
        orderStatus: id,
        page: page,
      };
      $.ajax({
        url: `${baseUrl}/frameCutOrder/pageList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: obj,
        success: (data) => {
          console.log(data);
          if (data.code == "00000") {
            //截帧数

            data.dataList.map((item, index) => {
              item.ts = dayjs(item.ts).format("YYYY-MM-DD HH:mm:ss");
            });
            this.tableData = data.dataList;
            this.pageTotal = data.totalCount;

            // this.perData.data = data.dataList;
            // this.perData.xaxis = data.data.date;
          }
          // console.log(this.tableData);
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    sureEvent(item) {
      // console.log(item);
      this.dialogDetail = true;
      //   this.getData(item);
      this.itemId = item.id;
    },
    //查看
    check(item) {
      this.$refs.dialogTalbe.showDialog(item.id);
    },
  },
  created() {
    this.getPer();
    this.getDate();
    this.getChannel();
  },
  mounted() {
    this.getPer();
  },
};
</script>
<style lang="scss" scoped>
.query {
  background: #fff;
  padding: 16px 16px;
  margin-bottom: 20px;
}
.pieChart {
  padding-top: 20px;
  background: #fff;
  height: 360px;
  border-radius: 20px;
  -webkit-box-shadow: 3px 3px 5px #dcdfe6;
  box-shadow: 3px 3px 5px #dcdfe6;
}

.tableBox {
  margin-top: 20px;
  color: #000;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  -webkit-box-shadow: 3px 3px 5px #dcdfe6;
  box-shadow: 3px 3px 5px #dcdfe6;
}
.pageBox {
  text-align: right;
  padding: 10px;
  background: #fff;
}
.rowClassName {
  height: 40px;
}
</style>