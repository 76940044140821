<template>
  <div :class="className" :style="{height:height,width:width}"></div>
</template>

<script>
// import echarts from 'echarts'
let echarts = require("echarts/lib/echarts");
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "@/utils";

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "320px",
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
watch:{
  chartData: {
    deep: true,
    handler(val) {
      console.log(val)
      this.setOptions(val);
    },
  },
},
  mounted() {
    this.initChart();
    this.__resizeHanlder = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHanlder);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
 setOptions(obj) {
      console.log(obj);
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
           textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
          },
        },
        legend: {
          left: "center",
          bottom: "10",
          // data: ["Industries", "Technology", "Forex", "Gold", "Forecasts"],
        },
        calculable: true,
        series: [
          {
            name: "详情",
            type: "pie",
            // roseType: "radius",
            radius: [15, 95],
            center: ["50%", "38%"],
            data: obj.data,
            animationEasing: "cubicInOut",
            animationDuration: 2600,
          },
        ],
      });
    },
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
       this.chart.on("click", this.lineClick); //里面有两个参数 第一个参数是你要的事件是什么 第二参数是 点击事件
    },
    lineClick(val) {
      // console.log(val);
      this.$emit('perChartFun',val.data.v)
    },
  },
};
</script>
