<template>
  <div class="secondlevelTable">
    <el-dialog title="状态码详情" :visible.sync="centerdelet" width="80%" :close-on-click-modal="false" :before-close="handleClose">
      <div class="content">
        <div class="export_btn">
          <el-button type="primary" icon="el-icon-document" @click="exportBtn()"> 导出 Excel </el-button>
        </div>
        <el-table :data="tableData" style="width: 100%" height="400" highlight-current-row>
          <el-table-column prop="orderId" label="序号" width="50" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="childOrderId" label="子订单ID" align="center">
          </el-table-column>
          <el-table-column prop="date" label="日期" align="center">
          </el-table-column>
          <el-table-column prop="childOrderStatus" label="子订单状态" align="center">
          </el-table-column>
           <el-table-column prop="desensitizationFlag" label="脱敏状态" align="center">
          </el-table-column>
          <el-table-column prop="deviceId" label="设备id" align="center">
          </el-table-column>
          <el-table-column prop="channel" label="渠道" align="center">
          </el-table-column>
           <el-table-column prop="successCutframeNumber" label="成功截帧数" align="center">
          </el-table-column>
           <el-table-column prop="uploadFailedCutframeNumber" label="设备上传失败截针数" align="center">
          </el-table-column>
           <el-table-column prop="videoDeletedCutframeNumber" label="视频不存在截帧数" align="center">
          </el-table-column>
           <el-table-column prop="videoLostCutframeNumber" label="视频丢失截针数" align="center">
          </el-table-column>
            <el-table-column prop="pushSuccessCutframeNumber" label="推送成功截针数" align="center">
          </el-table-column>
          <el-table-column prop="pushFailCutframeNumber" label="推送失败截针数" align="center">
          </el-table-column>
          <el-table-column prop="desensitizationCutframeNumber" label="脱敏截针数" align="center">
          </el-table-column>
          <el-table-column prop="noDesensitizationCutframeNumber" label="未脱敏截针数" align="center">
          </el-table-column>
           <el-table-column prop="errorFrameCutList" label="错误截帧集合" align="center">
          </el-table-column>
           <el-table-column prop="pushSuccessFrameCutList" label="推送成功截帧集合" align="center">
          </el-table-column>
           <el-table-column prop="pushFailFrameCutList" label="推送失败截帧集合" align="center">
          </el-table-column>
           <el-table-column prop="desensitizationFrameCutList" label="脱敏截帧集合" align="center">
          </el-table-column>
           <el-table-column prop="noDesensitizationFrameCutList" label="未脱敏截帧集合" align="center">
          </el-table-column>
          <!-- <el-table-column prop="errorFrameCutNumber" label="异常截帧数" align="center">
          </el-table-column>
          <el-table-column prop="errorFrameCutLiStr" label="异常截帧集合" align="center">
          </el-table-column> -->
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="centerdelet = false">取 消</el-button>
        <el-button type="primary" @click="sure()" style="margin-left:40px;">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  components: {},
  props: {},
  data() {
    return {
      baseUrl: window.cfg.cutPhotoUrl,
      centerdelet: false,
      tableData: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    showDialog(id) {
      this.centerdelet = true;
      this.getDetail(id);
    },
    sure() {
      this.$emit("sureEvent");
      this.centerdelet = false;
    },
    //查看
    // checkItem(item) {
    //   this.$emit("sureEvent", item);
    // },
    handleClose() {
      this.centerdelet = false;
    },
    //获取二级表格
    getDetail(id) {
      let { baseUrl } = this;
      console.log(id);
      this.id = id;
      $.ajax({
        url: `${baseUrl}/frameCutOrder/pieDetaiList`,
        type: "GET",
        dataType: "JSON",
        contentType: "application/json",
        data: {
          id: id,
        },
        success: (data) => {
          console.log(data.dataList);
          if (data.code == "00000") {
            data.dataList.map((item, index) => {
               item.date = dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")//日期
              //  item.errorFrameCutLiStr=item.errorFrameCutList.join(",")//异常截帧集合以逗号隔开
               item.errorFrameCutList=item.errorFrameCutList.join(",")//错误截帧集合
               item.pushSuccessFrameCutList=item.pushSuccessFrameCutList.join(",")//推送成功截帧集合
               item.pushFailFrameCutList=item.pushFailFrameCutList.join(",")//推送失败截帧集合
               item.desensitizationFrameCutList=item.desensitizationFrameCutList.join(",")//脱敏截帧集合
               item.noDesensitizationFrameCutList=item.noDesensitizationFrameCutList.join(",")//未脱敏截帧集合
            });
            this.tableData = data.dataList;
          }
        },
        error: (err) => {
          console.log(999999999);
        },
      });
    },
    //导出
    exportBtn() {
      let { baseUrl } = this;
      let href = `${baseUrl}/frameCutOrder/pieDetaiListExport?id=${this.id}`;
      var a = document.createElement("a");
      a.href = href;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.alertdelet {
  .el-dialog__title {
    margin-left: 10px !important;
  }
  .el-dialog {
    width: 360px !important;
    height: 230px !important;
    border-radius: 8px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }
  .delehint {
    vertical-align: middle;
    margin-left: 10px;
    display: inline-block;
    width: 225px;
    text-align: left;
  }
  .dialog-footer {
    text-align: center;
  }
  .dele_desc {
    margin-top: 20px;
  }
}
</style>